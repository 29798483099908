<template>
	<pui-modal-dialog
		v-if="show"
		:cancelText="$t('form.cancel')"
		:okText="$t('form.accept')"
		:disableOk="disableOk"
		:titleText="titleText"
		:messageText="messageText"
		dialogName="resetPasswordUser"
		ref="resetPasswordUser"
	>
		<template slot="message">
			<v-layout row wrap>
				<v-flex xs12>
					<pui-text-field
						toplabel
						:label="$t('userappresetpassword.oldpassword')"
						v-model="oldPassword"
						type="password"
						required
						realtime
					></pui-text-field>
				</v-flex>
				<v-flex xs12>
					<pui-text-field
						toplabel
						:label="$t('userappresetpassword.newpassword')"
						v-model="newPassword"
						type="password"
						required
						realtime
					></pui-text-field>
				</v-flex>
			</v-layout>
		</template>
	</pui-modal-dialog>
</template>

<script>
export default {
	data() {
		return {
			show: false,
			disableOk: true,
			titleText: this.$t('userappresetpassword.title'),
			messageText: '',
			usr: null,
			oldPassword: null,
			newPassword: null
		};
	},
	props: {
		modelName: {
			type: String,
			required: true
		}
	},
	watch: {
		// eslint-disable-next-line no-unused-vars
		oldPassword(newValue) {
			this.disableOk = this.oldPassword == null || this.oldPassword === '' || this.newPassword == null || this.newPassword === '';
		},
		// eslint-disable-next-line no-unused-vars
		newPassword(newValue) {
			this.disableOk = this.oldPassword == null || this.oldPassword === '' || this.newPassword == null || this.newPassword === '';
		}
	},
	mounted() {
		this.subscribeToModalDialogEvent();
	},
	methods: {
		subscribeToModalDialogEvent() {
			const self = this;
			this.$puiEvents.$on('pui-modalDialog-resetPasswordUser-ok', () => {
				const thisModel = this.$store.getters.getModelByName(this.modelName);
				if (thisModel) {
					const url = thisModel.url.setPassword;
					const params = { usr: this.usr, oldPassword: this.oldPassword, newPassword: this.newPassword };
					this.$puiRequests.getRequest(
						url,
						params,
						// eslint-disable-next-line no-unused-vars
						response => {
							self.show = false;
							this.$puiEvents.$emit('onPui-action-running-ended-' + self.modelName);
						},
						error => {
							if (error.response && error.response.data) {
								let message = error.response.data.message;
								if (error.response.data.internalCode === 202) {
									message = this.$t('userappresetpassword.202');
									if (message == null || message === '') {
										message = error.response.data.message;
									}
								}
								this.$puiNotify.error(message);
							}
						}
					);
				}
			});
			this.$puiEvents.$on('pui-modalDialog-resetPasswordUser-cancel', () => {
				self.show = false;
				this.$puiEvents.$emit('onPui-action-running-ended-' + self.modelName);
			});
			this.$puiEvents.$on('pui-modalDialog-resetPasswordUser-show', usr => {
				this.usr = usr;
				this.oldPassword = null;
				this.newPassword = null;
				this.show = true;
			});
		}
	},
	destroyed() {
		this.$puiEvents.$off('pui-modalDialog-resetPasswordUser-ok');
		this.$puiEvents.$off('pui-modalDialog-resetPasswordUser-cancel');
		this.$puiEvents.$off('pui-modalDialog-resetPasswordUser-show');
	}
};
</script>
