<template>
	<div>
		<pui-datatable :modelName="model" :actions="actions"></pui-datatable>
		<userappresetpassworddialog :modelName="model"></userappresetpassworddialog>
	</div>
</template>

<script>
import UserAppActions from './UserAppActions';
import UserAppResetPasswordDialog from './UserAppResetPasswordDialog';

export default {
	name: 'userappgrid',
	components: {
		userappresetpassworddialog: UserAppResetPasswordDialog
	},
	data() {
		return {
			model: 'userapp',
			actions: UserAppActions.actions
		};
	}
};
</script>
