import utilActions from '@/utils/actions';

const resetPasswordAction = {
	id: 'resetPasswordAction',
	selectionType: 'single',
	label: 'puiaction.resetpassword',
	functionality: 'WRITE_USER_APP',
	// eslint-disable-next-line no-unused-vars
	checkAvailability: function(vue, rows) {
		return true;
	},
	runAction: function(vue, action, model, registries) {
		var dataRegistry = registries[0];
		vue.$puiEvents.$emit('pui-modalDialog-resetPasswordUser-show', dataRegistry.usr);
	}
};

const disableUserAction = {
	id: 'disableUserAction',
	selectionType: 'single',
	label: 'puiaction.disableuser',
	functionality: 'WRITE_USER_APP',
	checkAvailability: function(vue, rows) {
		if (rows !== undefined) {
			for (let index = 0, length = rows.length; index < length; index++) {
				const element = rows[index];
				if (element && element.disabled === 1) {
					return false;
				}
			}
		}

		return true;
	},
	runAction: function(vue, action, model, registries) {
		var dataRegistry = registries[0];
		var usr = dataRegistry.usr;
		var urlController = model.url.disableUser + '?usr=' + usr;
		var params = {};
		vue.$puiRequests.getRequest(
			urlController,
			params,
			// eslint-disable-next-line no-unused-vars
			response => {
				vue.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
				utilActions.notifySuccess(vue, vue.$puiI18n.t(action.label));
			},
			// eslint-disable-next-line no-unused-vars
			e => {
				vue.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
				utilActions.notifyError(vue, vue.$puiI18n.t(action.label));
			}
		);
	}
};

const disableMassiveUserAction = {
	id: 'disableMassiveUserAction',
	selectionType: 'multiple',
	label: 'puiaction.disablemassiveuser',
	functionality: 'WRITE_USER_APP',
	checkAvailability: function(vue, user) {
		return !(user.disabled === 1);
	},
	runAction: function(vue, action, model, registries) {
		var counter = registries.length;
		var progress = 0;
		var success = true;
		var usr = null;
		for (let i = 0, length = registries.length; i < length; i++) {
			const registry = registries[i];
			var dataRegistry = registry;
			usr = dataRegistry.usr;
			var urlController = model.url.disableUser + '?usr=' + usr;
			vue.$puiRequests.getRequest(
				urlController,
				{},
				// eslint-disable-next-line no-unused-vars
				response => {
					progress++;
					finish();
				},
				// eslint-disable-next-line no-unused-vars
				e => {
					this.success = false;
					progress++;
					finish();
				}
			);
		}

		// Las peticiones son asíncronas, este código estaba puesto al finalizar el getRequest por lo que se ejecutaba antes de que llegara la respuesta del servidor y por eso no se actualizaba bien la tabla, debe ejecutarse al ir recibiendo las peticiones.
		function finish() {
			if (counter === progress) {
				vue.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
				if (success) {
					utilActions.notifySuccess(vue, vue.$puiI18n.t(action.label));
				} else {
					utilActions.notifyError(vue, vue.$puiI18n.t(action.label));
				}
			}
		}
	}
};
const enableUserAction = {
	id: 'enableUserAction',
	selectionType: 'single',
	label: 'puiaction.enableuser',
	functionality: 'WRITE_USER_APP',
	checkAvailability: function(vue, rows) {
		if (rows !== undefined) {
			for (let index = 0, length = rows.length; index < length; index++) {
				const element = rows[index];
				if (element && element.disabled === 0) {
					return false;
				}
			}
		}

		return true;
	},
	runAction: function(vue, action, model, registries) {
		var dataRegistry = registries[0];
		var usr = dataRegistry.usr;
		var urlController = model.url.enableUser + '?usr=' + usr;
		var params = {};
		vue.$puiRequests.getRequest(
			urlController,
			params,
			// eslint-disable-next-line no-unused-vars
			response => {
				vue.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
				utilActions.notifySuccess(vue, vue.$puiI18n.t(action.label));
			},
			// eslint-disable-next-line no-unused-vars
			e => {
				vue.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
				utilActions.notifyError(vue, vue.$puiI18n.t(action.label));
			}
		);
	}
};

export default {
	actions: [resetPasswordAction, disableUserAction, disableMassiveUserAction, enableUserAction]
};
