const getRouteWithOutMyModel = function (model) {
	const currentRouter = this.$router.currentRoute.path;
	var routeTokens = currentRouter.split('/');

	for (let i = routeTokens.length; i > 0; i--) {
		if (routeTokens[i] === model.name) {
			routeTokens.length = i;
			return routeTokens.join('/');
		}
	}
	return currentRouter;
};

export default {
	goToModelInsertOrCopy(model, isCopy, pk) {
		const dynamicRoute = getRouteWithOutMyModel.call(this, model);
		const params = {
			router: this.$router,
			url: isCopy
				? `${dynamicRoute}/${model.name}/copy/${this.$puiUtils.utf8ToB64(JSON.stringify(pk))}`
				: `${dynamicRoute}/${model.name}/create/new`,
			params: null,
			query: {},
			replace: !!this.$router.currentRoute.params.method
		};
		this.$store.dispatch('puiRouterGoToUrl', params);
	},
	goToModelUpdate(model, registries) {
		const pk = this.getPkFromModel(model, registries[0]);
		const params = {
			router: this.$router,
			url: getRouteWithOutMyModel.call(this, model) + `/${model.name}/update/${this.$puiUtils.utf8ToB64(JSON.stringify(pk))}/`,
			params: null,
			query: {}
		};
		this.$store.dispatch('puiRouterGoToUrl', params);
	},
	notifyError(title, message) {
		const messageEvent = message || this.$puiI18n.t('puiaction.notifyError');
		const titleEvent = this.$puiI18n.t('puiaction.notifyTitle') + (title ? ' > ' + title : '');
		this.$puiNotify.error(messageEvent, titleEvent);
	},
	notifySuccess(title, message) {
		const messageEvent = message || this.$puiI18n.t('puiaction.notifySuccess');
		const titleEvent = this.$puiI18n.t('puiaction.notifyTitle') + (title ? ' > ' + title : '');
		this.$puiNotify.success(messageEvent, titleEvent);
	},
	getPkFromModel(model, registry) {
		const pk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				pk[column.name] = registry[column.name];
			}
		}
		return pk;
	},
	cloneObject(object) {
		return JSON.parse(JSON.stringify(object));
	},
	finish(counter, progress, success, modelName, label) {
		if (counter === progress) {
			var message;
			this.$puiEvents.$emit(`onPui-action-running-ended-${modelName}`);
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + label;
			if (success) {
				message = this.$puiI18n.t('puiaction.notifySuccess');
				this.$puiNotify.success(message, title);
			} else {
				message = this.$puiI18n.t('puiaction.notifyError');
				this.$puiNotify.error(message, title);
			}
		}
	}
};
